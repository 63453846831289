<template>
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-indent -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-row>
    <b-card>
      <b-card-header><b class="h3">Product Detail</b></b-card-header>
      <b-card-body>
        <div class="container-ctrl">
          <b class="h1">Goods Receipt Note</b>
          <!-- <p>Please choose the right category for your product.</p> -->
          <hr />
          <b-row>
            <b-col md="4">
              <b-form-group label="Reciept date:" label-cols-md="3">
                <flat-pickr
                  v-model="model.reciept_date"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y/m/d H:i:ss',
                  }"
                  placeholder="Choose a date"
                />
              </b-form-group>
            </b-col>
            <br />
            <b-col md="4">
              <b-form-group label="Supplier:" label-cols-md="3">
                <v-select
                  v-if="supplierOptions"
                  v-model="model.supplier_id"
                  label="name"
                  :reduce="x => x.id"
                  :options="supplierOptions"
                />
              </b-form-group>
            </b-col>
            <!-- Table -->
            <b-col md="12" class="my-2">
              <b-table
                :sticky-header="true"
                :items="model.items"
                :fields="fields"
                striped
                responsive
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                style="min-height: 300px;"
              >
                <template #cell(name)="item">
                  <v-select
                    v-if="wareHouseOptions"
                    v-model="model.items[item.index]"
                    label="name_temp"
                    :options="wareHouseOptions"
                    @input="handlerChange"
                  />
                </template>
                <template #cell(variation)="item">
                  <span
                    v-if="
                      item.item.name_att1 !== null &&
                        item.item.name_att1 !== undefined
                    "
                  >
                    {{ item.item.name_att1 }}
                  </span>
                  <span
                    v-if="
                      item.item.name_att2 !== null &&
                        item.item.name_att2 !== undefined
                    "
                  >
                    - {{ item.item.name_att2 }}
                  </span>
                  <span
                    v-if="
                      item.item.name_att3 !== null &&
                        item.item.name_att3 !== undefined
                    "
                  >
                    - {{ item.item.name_att3 }}
                  </span>
                </template>
                <template #cell(quantity)="item">
                  <b-input-group prepend="+" class="input-group-merge">
                    <b-form-input
                      v-model="model.items[item.index].quantity"
                      type="number"
                      min="0"
                      @input="handlerSummaryQuantity"
                    />
                  </b-input-group>
                </template>
                <template #cell(unit_price)="item">
                  <b-input-group prepend="$" class="input-group-merge">
                    <b-form-input
                      v-model="model.items[item.index].unit_price"
                      type="number"
                      min="0"
                      @input="handlerSummaryUnitPrice"
                    />
                  </b-input-group>
                </template>
                <template #cell(total)="item">
                  <b-form-input
                    v-model="model.items[item.index].total"
                    type="number"
                    readonly
                  />
                </template>
              </b-table>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="w-100"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col md="9" />
            <b-col md="3">
              <b-form-group label="Total quantity:" label-cols-md="8">
                <b-form-input v-model="model.total_quantity" readonly />
              </b-form-group>
            </b-col>
            <b-col md="9" />
            <b-col md="3">
              <b-form-group label="Total price:" label-cols-md="8">
                <b-input-group prepend="$" class="input-group-merge">
                  <b-form-input v-model="model.total_price" readonly />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Product for promotion:" label-cols-md="3">
                <b-form-radio v-model="model.status" :value="1">
                  Pay all off
                </b-form-radio>
                <b-form-radio v-model="model.status" :value="2" class="my-1">
                  Unpaid
                </b-form-radio>
                <b-form-radio v-model="model.status" :value="3">
                  Partial payment
                </b-form-radio>
                <b-input-group
                  prepend="$"
                  class="input-group-merge mt-1 w--145"
                >
                  <b-form-input
                    v-model="model.payment"
                    type="number"
                    min="0"
                    :readonly="model.status === 3 ? false : true"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Note:" label-cols-md="3">
                <b-form-textarea
                  v-model="model.note"
                  rows="6"
                  max-rows="6"
                  no-resize
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="handlerSubmit"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </b-row>
</template>
<script>
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BFormTextarea,
  BFormRadio,
  BTable,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BFormTextarea,
    BFormRadio,
    BTable,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        { key: 'name', label: 'Product name' },
        { key: 'variation', label: 'Variation' },
        { key: 'sku', label: 'SKU' },
        { key: 'stock', label: 'stock', sortable: true },
        { key: 'price', label: 'Selling price', sortable: true },
        { key: 'sold', label: 'Sold', sortable: true },
        { key: 'quantity', label: 'Quantity' },
        { key: 'unit_price', label: 'Unit Price' },
        { key: 'total', label: 'Total' },
      ],
      model: {
        supplier_id: null,
        quantity: 0,
        type: 0,
        status: 1,
        reciept_date: this.getCurrentDate(true),
        items: [
          {
            name: null,
            variation: null,
            sku: null,
            stock: null,
            price: null,
            sold: null,
            quantity: 0,
            unit_price: 0,
            total: 0,
          },
        ],
        note: null,
        total_quantity: 0,
        payment: null,
        total_price: null,
      },
      supplierOptions: [],
      wareHouseOptions: [],
    }
  },
  mounted() {
    this.loadSupplierList()
    this.loadWarehouseList()
  },
  methods: {
    repeateAgain() {
      this.model.items.push({
        name: null,
        variation: null,
        sku: null,
        stock: null,
        price: null,
        sold: null,
        quantity: 0,
        unit_price: 0,
        total: 0,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    handlerSummaryQuantity() {
      this.model.total_quantity = 0
      this.model.items.map((item, index) => {
        this.model.total_quantity += Number(item.quantity)
        this.model.items[index].total = Number(
          Number(item.quantity) + Number(item.stock),
        )
      })
    },
    handlerSummaryUnitPrice() {
      this.model.total_price = 0
      this.model.items.map(item => {
        this.model.total_price += Number(item.unit_price * item.quantity)
      })
    },
    async handlerSubmit() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        supplier_id: this.model.supplier_id,
        quantity: this.model.quantity,
        type: this.model.type,
        status: this.model.status,
        reciept_date: this.model.reciept_date,
        note: this.model.note,
        total_quantity: this.model.total_quantity,
        total_price: this.model.total_price,
      }
      if (this.model.items && this.model.items.length > 0) {
        const array = []
        this.model.items.map(item => {
          array.push({
            warehouse_id: item.id,
            quantity: item.quantity,
            price: item.unit_price,
          })
        })
        params.items = JSON.stringify(array)
      }
      if (this.model.status === 3) {
        params.payment = this.model.payment
      }
      try {
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_history_warehouse`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Update Warehouse success',
              },
            })
            // this.model = {
            //   supplier_id: null,
            //   quantity: 0,
            //   type: 0,
            //   status: 1,
            //   reciept_date: this.getCurrentDate(true),
            //   items: [],
            //   note: null,
            //   total_quantity: 0,
            //   payment: null,
            //   total_price: null,
            // }
            this.$router.push('/warehouse/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadSupplierList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_suppliers?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.supplierOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadWarehouseList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_warehouses?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.items.map(item => {
              const i = { ...item, name_temp: item.name, quantity: 0 }
              if (item.name_att1 !== null) {
                i.name_temp = `${i.name} (${item.name_att1})`
              }
              if (item.name_att2 !== null) {
                i.name_temp = `${i.name} (${item.name_att1}-${item.name_att2})`
              }
              if (item.name_att3 !== null) {
                i.name_temp = `${i.name} (${item.name_att1}-${item.name_att2}-${item.name_att3})`
              }
              this.wareHouseOptions.push(i)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="sass" scoped>
.w--145
  width: 145px
</style>
